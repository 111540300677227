/* eslint-disable camelcase */

import { UserType } from '@/globals/constants'
import { Banner } from '@/features/banners'
import { ApiUserDetailsModel } from './apiUserDetailsModel'
import { RawAuthProfileSchema } from './rawAuthProfileSchema'
import { UserRole } from './userRole'

// The "final form" of the user identity, this is what will exist
//    in the vuex store, and what will be modified by the identity service.
//    It's the final product of the identity/auth/app initialization as far as the user goes
// a merger of `RawAuthProfileSchema` and `ApiUserDetailsModel`.

/** The entirety of the User's Identity Profile. Is recreated & replaced each time the user's identity changes. */
export class UserIdentity implements RawAuthProfileSchema, ApiUserDetailsModel {
  // ===================
  // Additional Fields
  // ===================

  selectedBanner: string
  availableBanners: string[]
  roles: UserRole[]
  roleNames: string[]
  appNames: string[]

  // Authed SSO is the base user's SSO, used when viewas overwrites sso
  authedSso: string | number

  isViewingAs: boolean = false
  viewAsBanner: string | null = null
  viewAsUser: ApiUserDetailsModel | null = null
  viewAsSso: number | string | null = null

  // Authenticated User Details
  authedUserDetails: ApiUserDetailsModel

  // ===================
  // RawAuthProfileSchema
  // ===================
  amr: string[]
  auth_time: number
  banner: string
  branch_id: string
  eclipse_user_login: string
  email: string
  family_name: string
  given_name: string
  hire_date: string
  idp: string
  imp_email: string
  manager_sso: string
  name: string
  picture: string
  role: string | string[]
  s_hash: string
  sid: string
  sso: string | number
  sub: string

  // ===================
  // ApiUserDetailsModel
  // ===================

  companyId: number = 0
  firstName: string = ''
  lastName: string = ''
  branch: string = ''
  jobTitle: string = ''
  jobCode: string = ''
  userType: UserType = 'Unset'
  regions: string[] = []
  permission: string[] = []
  regionCode: string = ''
  bannerRegionCode: string = ''
  enteredByInits: string = ''
  yearToDateSales?: number | null | undefined
  rolling12Sales?: number | null | undefined
  rolling12MarginPercent?: number | null | undefined
  branchName: string = ''
  branchId: number = 0
  bannerBranchNumber: number = 0
  imageFolderName?: string | null | undefined
  imageUrl: string = ''
  hireDate?: string | null | undefined
  phoneNumber: string = ''
  canViewAs?: boolean | undefined

  constructor (rawProfile: RawAuthProfileSchema, userDetails: ApiUserDetailsModel, selectedBanner: string, availableBanners: string[], roles: UserRole[]) {
    this.selectedBanner = selectedBanner
    this.availableBanners = availableBanners
    this.roles = roles

    // Extract out individual role names for utility purposes
    this.roleNames = roles
      .map((role) => role.name)
      .filter((role) => !!role) as string[]

    // Extract out individual app names for utility purposes
    this.appNames = roles.reduce<string[]>((aggregate, current) => {
      return aggregate.concat(current.apps)
    }, [])

    this.authedSso = rawProfile.sso

    this.amr = rawProfile.amr
    this.auth_time = rawProfile.auth_time
    this.banner = rawProfile.banner
    this.branch_id = rawProfile.branch_id
    this.eclipse_user_login = rawProfile.eclipse_user_login
    this.email = rawProfile.email
    this.family_name = rawProfile.family_name
    this.given_name = rawProfile.given_name
    this.hire_date = rawProfile.hire_date
    this.idp = rawProfile.idp
    this.imp_email = rawProfile.imp_email
    this.manager_sso = rawProfile.manager_sso
    this.name = rawProfile.name
    this.picture = rawProfile.picture
    this.role = rawProfile.role
    this.s_hash = rawProfile.s_hash
    this.sid = rawProfile.sid
    this.sso = rawProfile.sso
    this.sub = rawProfile.sub

    this.authedUserDetails = userDetails
    this.setUserDetails(userDetails, false)
  }

  private setUserDetails (userDetails: ApiUserDetailsModel, isViewAs: boolean) {
    this.companyId = userDetails.companyId
    this.firstName = userDetails.firstName
    this.lastName = userDetails.lastName
    this.branch = userDetails.branch
    this.email = userDetails.email
    this.jobTitle = userDetails.jobTitle
    this.jobCode = userDetails.jobCode
    this.userType = userDetails.userType
    this.regions = userDetails.regions
    this.permission = userDetails.permission
    this.regionCode = userDetails.regionCode
    this.bannerRegionCode = userDetails.bannerRegionCode
    this.enteredByInits = userDetails.enteredByInits
    this.yearToDateSales = userDetails.yearToDateSales
    this.rolling12Sales = userDetails.rolling12Sales
    this.rolling12MarginPercent = userDetails.rolling12MarginPercent
    this.branchName = userDetails.branchName
    this.branchId = userDetails.branchId
    this.bannerBranchNumber = userDetails.bannerBranchNumber
    this.imageFolderName = userDetails.imageFolderName
    this.imageUrl = userDetails.imageUrl
    this.hireDate = userDetails.hireDate
    this.phoneNumber = userDetails.phoneNumber
    this.sso = userDetails.sso

    if (!isViewAs) {
      this.canViewAs = userDetails.canViewAs
    }
  }

  public setViewAsUser (viewAsSso: number, viewAsBanner: string, userDetails: ApiUserDetailsModel) {
    this.viewAsBanner = viewAsBanner
    this.viewAsSso = viewAsSso
    this.viewAsUser = userDetails
    this.isViewingAs = true

    // Replace authed user details with viewas details
    this.setUserDetails(userDetails, true)
  }

  public clearViewAs () {
    this.viewAsBanner = null
    this.viewAsSso = null
    this.viewAsUser = null
    this.isViewingAs = false

    // Restore authed user details
    this.setUserDetails(this.authedUserDetails, true)
  }

  /** If the user is the provided banner */
  public isBanner (banner: Banner) {
    return this.getDefinitiveBanner() === banner
  }

  public hasBanner (banner: string) {
    return this.availableBanners.some(x => x === banner)
  }

  public hasApp (appName: string) {
    return !!this.appNames.find(x => x.includes(appName))
  }

  public hasRole (role: string) {
    return !!this.roleNames.find(x => x.includes(role))
  }

  public hasAnyRole (roles: string[]) {
    return !!roles.find(r => this.hasRole(r))
  }

  public getDefinitiveBanner () {
    if (this.isViewingAs && this.viewAsBanner) {
      return this.viewAsBanner
    }

    return this.selectedBanner
  }

  public getDefinitiveUserType () {
    if (this.isViewingAs && this.viewAsUser) {
      return this.viewAsUser.userType
    }

    return this.userType
  }
}
