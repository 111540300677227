<template>
  <div>
    <base-dialog
      width="100%"
      :show="show"
      :valid="hasChanges"
      @close="close"
      @submit="submit"
      @keydown.esc="close">
      <template slot="title">
        {{ label }}
      </template>
      <v-form
        ref="form"
        slot="content">
        <v-switch
          v-if="eskerCriteria"
          v-model="isEskerCustomer"
          dense
          label="Esker Customer">
        </v-switch>
        <div
          v-if="isEskerCustomer"
          class="ml-10">
          <v-switch
            v-model="pricingDiscrepancyHold"
            class="d-inline-block"
            label="Pricing Discrepancy Hold">
          </v-switch>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                medium
                class="d-inline-block ml-1 mb-3"
                v-on="on">
                info
              </v-icon>
            </template>
            <div style="width: 300px">
              If toggled on, the Customer's order will be held for review if their PO's pricing is different than the {{ getBannerName }} price. If the pricing discrepancy is within the pricing tolerance, we will use the {{ getBannerName }} price.
            </div>
          </v-tooltip>
          <br />
          <v-switch
            v-model="defaultAsPending"
            class="d-inline-block"
            :label="isPlattBanner ? 'Default as Pending' : 'Default as Bid'">
          </v-switch>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                medium
                class="d-inline-block ml-1 mb-3"
                v-on="on">
                info
              </v-icon>
            </template>
            <div

              style="width: 300px">
              If toggled on, all successful POs submitted through Esker will be created in the
              <span v-if="!isPlattBanner">
                Eclipse as a Bid.
              </span>
              <span v-if="isPlattBanner">
                AS400 as Pending.
              </span>
            </div>
          </v-tooltip>
        </div>

        <v-switch
          v-if="!isPlattBanner"
          v-model="isKeyCustomer"
          label="Key Customer">
        </v-switch>

        <v-switch
          v-if="!isPlattBanner"
          v-model="isTargetCustomer"
          label="Target Customer">
        </v-switch>

        <v-switch
          v-if="!isPlattBanner"
          v-model="isDevelopmentCustomer"
          label="Development Designation">
        </v-switch>
        <v-switch
          v-if="!isPlattBanner"
          v-model="grayMarketReseller"
          :disabled="!userIsContentTeam"
          label="Gray Market">
        </v-switch>
      </v-form>
    </base-dialog>
    <confirmation-dialog
      max-width="634"
      :show="isConfirmationDialogOpen"
      title="Editing gray market designation will update the designation for all associated accounts"
      action-label="Confirm"
      :confirm-action="confirmDesignation"
      :cancel-action="closeConfirmationDialog">
    </confirmation-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog.vue'
import { updateEskerAndPricingDiscrepancyHold } from '@/api/customers'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { bannerProvider } from '@/features/banners'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DesignationsAndBadgesMenu',
  components: {
    BaseDialog,
    ConfirmationDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      label: 'Designations & Badges',
      isEskerCustomer: this.customer.isEskerCustomer,
      pricingDiscrepancyHold: this.customer.pricingDiscrepancyHold,
      defaultAsPending: this.customer.defaultAsPending,
      isKeyCustomer: false,
      isTargetCustomer: false,
      isDevelopmentCustomer: false,
      grayMarketReseller: false,
      isConfirmationDialogOpen: false,
      contentTeamJobCode: '90E168'
    }
  },

  computed: {
    ...mapGetters('user', ['isPlattBanner', 'userJobCode']),
    hasChanges () {
      return ((this.isKeyCustomer !== this.customer.isKeyCustomer) ||
              (this.isTargetCustomer !== this.customer.isTargetCustomer) ||
              (this.isDevelopmentCustomer !== this.customer.isDevelopmentCustomer) ||
              (this.grayMarketReseller !== this.customer.grayMarketReseller) ||
              (this.isEskerCustomer !== this.customer.isEskerCustomer) ||
              (this.pricingDiscrepancyHold !== this.customer.pricingDiscrepancyHold) ||
              (this.defaultAsPending !== this.customer.defaultAsPending)
      )
    },
    getBannerName () {
      return bannerProvider.getNameFromId(this.customer.companyId)
    },
    eskerCriteria () {
      // For RexGex, Esker settings are at the shipping account level(both Job and non-job ship-to),
      // do not show Esker toggle on Bill-to only or Enterprise customer level (work item 37706)
      if (!this.isPlattBanner) {
        return this.customer.shipTo
      }

      // For Platt, Esker settings are at the customer level
      if (this.isPlattBanner) {
        return !this.customer.isEnterprise
      }

      return false
    },
    userIsContentTeam () {
      return this.userJobCode === this.contentTeamJobCode
    }
  },
  watch: {
    isEskerCustomer (newValue) {
      if (!newValue) {
        this.pricingDiscrepancyHold = false
        this.defaultAsPending = false
      }
    },
    show: function (newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.isKeyCustomer = this.customer.isKeyCustomer
        this.isTargetCustomer = this.customer.isTargetCustomer
        this.isDevelopmentCustomer = this.customer.isDevelopmentCustomer
        this.grayMarketReseller = this.customer.grayMarketReseller
        this.isEskerCustomer = this.customer.isEskerCustomer
        this.pricingDiscrepancyHold = this.customer.pricingDiscrepancyHold
        this.defaultAsPending = this.customer.defaultAsPending
      }
    }
  },
  methods: {
    ...mapActions('currentCustomer', ['setKeyAndTarget', 'setGreyMarketReseller']),
    async submit () {
      if ((this.isKeyCustomer !== this.customer.isKeyCustomer) ||
          (this.isTargetCustomer !== this.customer.isTargetCustomer) ||
          (this.isDevelopmentCustomer !== this.customer.isDevelopmentCustomer)) {
        const isExclusivelyUpdatingKey = ((this.isKeyCustomer !== this.customer.isKeyCustomer) &&
        (this.isTargetCustomer === this.customer.isTargetCustomer))
        this.setKeyAndTarget({
          customerId: this.customer.customerId,
          isExclusivelyUpdatingKey,
          isKeyCustomer: this.isKeyCustomer,
          isTargetCustomer: this.isTargetCustomer,
          isDevelopmentCustomer: this.isDevelopmentCustomer
        })

        // eslint-disable-next-line vue/no-mutating-props
        this.customer.isKeyCustomer = this.isKeyCustomer

        // eslint-disable-next-line vue/no-mutating-props
        this.customer.isTargetCustomer = this.isTargetCustomer

        // eslint-disable-next-line vue/no-mutating-props
        this.customer.isDevelopmentCustomer = this.isDevelopmentCustomer
      }
      if (this.grayMarketReseller !== this.customer.grayMarketReseller) {
        this.isConfirmationDialogOpen = true
      }
      // if any Esker details have changed, seperate from key and target
      if (
        this.isEskerCustomer !== this.customer.isEskerCustomer ||
        this.pricingDiscrepancyHold !== this.customer.pricingDiscrepancyHold ||
        this.defaultAsPending !== this.customer.defaultAsPending
      ) {
        await updateEskerAndPricingDiscrepancyHold(this.customer.companyId, this.customer.customerId, this.isEskerCustomer, this.pricingDiscrepancyHold, this.defaultAsPending)
        this.customer.isEskerCustomer = this.isEskerCustomer
        this.customer.pricingDiscrepancyHold = this.pricingDiscrepancyHold
        this.customer.defaultAsPending = this.defaultAsPending
      }
      this.close()
    },
    close () {
      this.$emit('update:show', false)
    },
    confirmDesignation () {
      this.setGreyMarketReseller({
        customerId: this.customer.customerId,
        grayMarketReseller: this.grayMarketReseller
      })
      // eslint-disable-next-line vue/no-mutating-props
      this.customer.grayMarketReseller = this.grayMarketReseller
      this.closeConfirmationDialog()
    },
    closeConfirmationDialog () {
      this.isConfirmationDialogOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-message {
  display: none !important;
}
</style>
