<template>
  <base-dialog
    :show="dialogOpen"
    submit-label="Continue"
    @submit="submit"
    @close="close"
    @keydown.esc="close">
    <template slot="title">
      Create R+ MI Program
    </template>

    <v-form
      slot="content"
      ref="form"
      class="pt-3">
      <v-row
        class="text-center"
        justify="center">
        <v-col
          cols="12">
          <v-img
            src="@/assets/icons/RMI-logo.svg"
            max-height="100"
            max-width="100"
            class="mx-auto">
          </v-img>
        </v-col>
        <v-col cols="12">
          Create Rexel+ Managed Inventory program for {{ customer.name }}?
        </v-col>
      </v-row>
    </v-form>
  </base-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import BaseDialog from '@/components/BaseDialog.vue'
import gql from 'graphql-tag'

export default {
  name: 'CreateProgramDialog',
  components: {
    BaseDialog
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    customerKey: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return { }
  },
  computed: {
    dialogOpen: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapMutations('storefrontUsers', ['SET_STOREFRONT_MANAGEDINVENTORY_PROGRAMS']),
    async submit () {
      if (this.customer.name && this.customerKey) {
        try {
          const response = await this.$apollo.mutate({
            mutation: gql`
              mutation ProgramCreate($input: ManagedInventoryProgramInsertInput!) {
                managedInventoryProgramCreate(input: $input) {
                  data {
                    accounts {
                      ... on AdminCustomer {
                        __typename
                        key
                        displayName
                      }
                      ... on AdminCustomerGroup {
                        __typename
                        id
                        displayName
                      }
                    }
                    dbId
                    description
                    id
                    name
                  }
                  hasErrors
                  userErrors {
                    message
                  }
                }
              }
            `,
            variables: {
              input: {
                name: this.customer.name,
                description: null,
                accounts: [
                  {
                    key: this.customerKey
                  }
                ]
              }
            }
          })

          if (response.data.managedInventoryProgramCreate.data) {
            this.$store.commit('storefrontUsers/SET_STOREFRONT_MANAGEDINVENTORY_PROGRAMS', [response.data.managedInventoryProgramCreate.data])
            this.$store.commit('setToastMessage', {
              message: 'Successfully created managed inventory program.',
              status: 'success'
            })
          }
        } catch (error) {
          this.$store.commit('setToastMessage', {
            message: 'Failed to create managed inventory program.',
            status: 'error'
          })
        }
        this.close()
      }
    },
    close () {
      this.dialogOpen = false
      this.$refs.form.reset()
    }
  }
}
</script>
